import styled, { css } from 'styled-components'

const Support = () => {
  return (
    <GenesysDiv id="genesys-support-center" />
  )
}

const overrideStyles = css`
  /* disable all the underline animation */
  a:hover::after { content: none; }

  b, .font-bold { font-family: mabry-pro-bold, Sans-Serif; }

  /* landing page: http://localhost:3000/support#/  */
  .categoriesContainer a { overflow: hidden;  border-radius: 10px; border: 1px solid #242220; box-shadow: none; }
  .categoriesContainer a:hover { transform: scale(1.01); }

  /* Override link to pink */
  .topViewedArticlesList a:hover { color: #FC92D3; }
  .topViewedArticlesList a:hover svg { fill: #FC92D3; }

  /** Category Page */
  #nav-pane::after { content: none; }
  #nav-pane a:hover { background-color: #FAF4EE; }
  #nav-pane__list { overflow: hidden; }
  /* navbar: the main Accoun /HappyMoney/Payoff Loan/Security category */
  #nav-pane__list button span { font-size: 16px; line-height: 1.5rem; }
  #nav-pane__list > li > div:hover { background-color: #FAF4EE; }
  #nav-pane__list > li > div[class*="navPaneItemActive"] { background-color: #FAF4EE; }

  /* sub category */
  #nav-pane__list ul a span { font-size: 14px; line-height: 1.5rem}

  /* Subcategory page:
     http://localhost:3000/support#/categories/e6490b80-2e05-41d2-9dcd-2ccef09d782b
  */
  section[class*="SectionContainer_sectionContainer"] { padding-top: 24px; padding-bottom: 24px; }

  /* Subcategory page */
  section[class*="SectionContainer_sectionContainer"] { padding-top: 24px; padding-bottom: 24px; }
  section div[class*="CategoryGridItem_categoryGridItem"] { border: 1px solid black }
  section div[class*="CategoryGridItem_categoryGridItem"]:hover { background-color: #FAF4EE; box-shadow: none}

  /* hide "Articles" when it's empty
     http://localhost:3000/support#/categories/c26278b9-bed5-4684-9b9e-5e59256c847d
     Basically we check for the div contains EmptySection_emptySection class
     and hide it and the immediate previous H3 sibling.
  */
  *:has(+ div[class*="EmptySection_emptySection"]) { display: none; }
  div[class*="EmptySection_emptySection"] { display: none; }
  div[class*="EmptySection_emptySection"]:has(+ h3) { display: none; }

  div[class*="KnowledgeArticle_bodyContainer"] a { text-decoration-line: underline;}
  div[class*="KnowledgeArticle_bodyContainer"] a:hover { transform: scale(1.05) !important; }

`

const GenesysDiv = styled.div`
 ${ overrideStyles }
`
export default Support
